.App {
  max-width: 800px;
  margin: 2rem auto;
}

.serif {
	font-family: serif !important;
}
.ui.text.container {
	font-size: 18px;
	line-height: 1.8;
}

.serif .ui.input.text input {
	font-family: serif;
	padding: .1em .3em;
}
.ui.input.text input.compact {
	padding: .1em 0em;
}
.ui.input.text input::placeholder {
	color: rgb(0,0,0);
}

.space-bottom {
	margin-bottom: 4rem;
}

.rotate-45 {
	transform: rotate(45deg);
}
